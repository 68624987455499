import { useState } from "react";
import { Box, Typography } from "@mui/material";

// Função para calcular a cor do texto automaticamente
const getContrastColor = (bgColor) => {
  // Converte a cor para RGB
  const hex = bgColor.replace("#", "");
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  // Fórmula de luminância para calcular o contraste
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;

  // Retorna preto ou branco dependendo da luminância
  return luminance > 150 ? "#000000" : "#ffffff";
};

const CardTag = ({ color, text }) => {
  const [expanded, setExpanded] = useState(false);
  const textColor = getContrastColor(color);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: expanded ? "center" : "unset",
        width: expanded ? "auto" : 40,
        height: 20,
        backgroundColor: color,
        borderRadius: 1,
        cursor: "pointer",
        transition: "width 0.2s ease-in-out, padding 0.2s",
        padding: expanded ? "0 8px" : "0",
        overflow: "hidden",
        whiteSpace: "nowrap",
      }}
      onClick={() => setExpanded((prev) => !prev)}
    >
      {expanded && (
        <Typography
          variant="caption"
          sx={{ color: textColor, fontWeight: 500 }}
        >
          {text}
        </Typography>
      )}
    </Box>
  );
};

export default CardTag;
