import axios from "axios";

const baseAPI = process.env.REACT_APP_BASE_API_URL;
const parametroAssinanteAPI = axios.create({
  baseURL: `${baseAPI}api/funil/parametroassinante`,
});

async function listParametroAssinante(token) {
  const authToken = `Token ${token}`;
  const resp = await parametroAssinanteAPI.get("/", {
    headers: { Authorization: authToken },
  });
  return resp.data;
}

async function getParametroAssinante(token, pk) {
  const authToken = `Token ${token}`;
  const resp = await parametroAssinanteAPI.get(`/${pk}/`, {
    headers: { Authorization: authToken },
  });
  return resp.data;
}

async function addParametroAssinante(token, dados) {
  const authToken = `Token ${token}`;
  const resp = await parametroAssinanteAPI.post("/", dados, {
    headers: { Authorization: authToken, "Content-Type": "application/json" },
  });
  return resp.data;
}

async function updParametroAssinante(token, dados) {
  const authToken = `Token ${token}`;
  const resp = await parametroAssinanteAPI.put(`/${dados.id}/`, dados, {
    headers: { Authorization: authToken, "Content-Type": "application/json" },
  });
  return resp.data;
}

async function partialUpdParametroAssinante(token, dados) {
  console.log("dados: ", dados);
  const authToken = `Token ${token}`;
  const resp = await parametroAssinanteAPI.patch(
    `/${dados.assinante}/`,
    dados,
    {
      headers: { Authorization: authToken, "Content-Type": "application/json" },
    }
  );
  return resp.data;
}

async function delParametroAssinante(token, pk) {
  const authToken = `Token ${token}`;
  const resp = await parametroAssinanteAPI.delete(`/${pk}`, {
    headers: { Authorization: authToken },
  });
  return resp.data;
}

export {
  listParametroAssinante,
  getParametroAssinante,
  addParametroAssinante,
  updParametroAssinante,
  partialUpdParametroAssinante,
  delParametroAssinante,
};
