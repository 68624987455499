import axios from "axios";

const baseAPI = process.env.REACT_APP_BASE_API_URL;
const cardsAPI = axios.create({ baseURL: `${baseAPI}api/funil/cards` });

async function getCards(token) {
  const authToken = `Token ${token}`;
  const resp = await cardsAPI.get("/", {
    headers: { Authorization: authToken },
  });
  return resp.data;
}

async function getCard(token, pk) {
  const authToken = `Token ${token}`;
  const resp = await cardsAPI.get(`/${pk}`, {
    headers: { Authorization: authToken },
  });
  return resp.data;
}

async function newCard(token, dados) {
  console.log("dados no newCard", dados);
  const authToken = `Token ${token}`;
  const resp = await cardsAPI.post("/", dados, {
    headers: { Authorization: authToken, "Content-Type": "application/json" },
  });
  return resp.data;
}

async function updCard(token, dados) {
  console.log("dados no updCard", dados);
  const authToken = `Token ${token}`;
  let dadosToUpdate = { ...dados };
  if (dadosToUpdate.originalId) {
    dadosToUpdate.id = dadosToUpdate.originalId;
    delete dadosToUpdate.originalId;
  }
  if (dadosToUpdate.contato) {
    if (typeof dadosToUpdate.contato === "object") {
      dadosToUpdate.contato = dadosToUpdate.contato.id;
    }
  }
  const resp = await cardsAPI.put(`/${dadosToUpdate.id}`, dadosToUpdate, {
    headers: { Authorization: authToken, "Content-Type": "application/json" },
  });
  return resp.data;
}

async function delCard(pk, token) {
  const authToken = `Token ${token}`;
  const resp = await cardsAPI.delete(`/${pk}`, {
    headers: { Authorization: authToken },
  });
  return resp.data;
}

async function fileCardUpload(dados, token) {
  const authToken = `Token ${token}`;
  const resp = await cardsAPI.post("/upload/", dados, {
    headers: {
      Authorization: authToken,
      "Content-Type": "multipart/form-data",
    },
  });
  return resp.data;
}

async function getCardHistory(pk, token) {
  const authToken = `Token ${token}`;
  const resp = await cardsAPI.get(`/history/${pk}`, {
    headers: { Authorization: authToken },
  });
  return resp.data;
}

async function getCardFiles(pk, token) {
  const authToken = `Token ${token}`;
  const resp = await cardsAPI.get(`/${pk}/files/`, {
    headers: { Authorization: authToken },
  });
  return resp.data;
}

async function checkExpirationUpdates(token, lastUpdate) {
  // console.log("lastUpdate antes do encode:", lastUpdate);

  const authToken = `Token ${token}`;
  const encodedLastUpdate = lastUpdate ? encodeURIComponent(lastUpdate) : null; // <-- Aqui faz o encode

  const uri = encodedLastUpdate
    ? `/checkcardexpirationupdates/?last_checked=${encodedLastUpdate}`
    : "/checkcardexpirationupdates/";

  // console.log("URL chamada:", uri); // Verifique se está correto

  const resp = await cardsAPI.get(uri, {
    headers: { Authorization: authToken },
  });

  return resp.data;
}

async function getUserCardsList(token, page, pageSize, searchValue, status) {
  const authToken = `Token ${token}`;
  const searchquery = searchValue ? `&search=${searchValue}` : "";
  const statusquery = status ? `&status=A` : "";
  const resp = await cardsAPI.get(
    `/usercardslist/?page=${page}&page_size=${pageSize}${searchquery}${statusquery}`,
    {
      headers: { Authorization: authToken },
    }
  );
  return resp.data;
}

async function listMotivoAbandono(token) {
  const authToken = `Token ${token}`;
  const resp = await cardsAPI.get("/motivoabandono/", {
    headers: { Authorization: authToken },
  });
  return resp.data;
}

export {
  getCards,
  getCard,
  newCard,
  updCard,
  delCard,
  getUserCardsList,
  fileCardUpload,
  getCardHistory,
  getCardFiles,
  checkExpirationUpdates,
  listMotivoAbandono,
};
