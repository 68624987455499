import PropTypes from "prop-types";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import HomeBar from "../components/HomeBar/HomeBar";
import { useLocation } from "react-router-dom";
import {
  createFunilAssinante,
  getFunilAssinanteByAssinante,
  updEtapaFunilAssinante,
} from "../services/srvFunil";
import toast, { Toaster } from "react-hot-toast";
import { getTemplateBySetor } from "../services/SrvTemplateFunil";
import { useEffect, useRef, useState } from "react";
import {
  addParametroAssinante,
  getParametroAssinante,
} from "../services/SrvParametro";
import ParametroAssinanteCRM from "../components/ParametroAssinanteCRM";

function LightCRMPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

LightCRMPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Ajustes() {
  var nome = null;
  var token = null;
  var profile = null;
  var isAuthenticated = false;

  const location = useLocation();
  const { state } = location;

  if (state) {
    nome = state.nome;
    token = state.token;
    profile = state.profile;
    isAuthenticated = state.isAuthenticated;
  }

  const hasRun = useRef(false);
  const [value, setValue] = useState(0);
  const [funil, setFunil] = useState(null);
  const [etapas, setEtapas] = useState([]);
  const [parametros, setParametros] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [tempValue, setTempValue] = useState("");

  async function fetchFunilByAssinante() {
    await getFunilAssinanteByAssinante(token, profile.assinante).then(
      async (resp) => {
        if (resp === "") {
          const template = await getTemplateBySetor(token, profile.setor);
          await createFunilAssinante(
            token,
            profile.assinante,
            template.id
          ).then(
            (resp) => {
              console.log("funil: ", resp);
              setFunil(resp);
              toast.success("Funil inicializado com sucesso!");
            },
            (erro) => {
              toast.error(
                `Ocorreu um erro durante a inicialização do funil para o assinante: ${erro}`
              );
            }
          );
        } else {
          console.log("funil: ", resp);
          setFunil(resp);
        }
      },
      (erro) => {
        console.error(erro);
      }
    );
  }

  async function fetchParametroAssinante() {
    const parametros = await getParametroAssinante(
      token,
      profile.assinante
    ).then(
      (resp) => {
        return resp;
      },
      (erro) => {
        console.error(erro);
        return null;
      }
    );
    if (parametros === null) {
      await addParametroAssinante(token, { assinante: profile.assinante }).then(
        (resp) => {
          console.log("parametros: ", resp);
          setParametros(resp);
        },
        (erro) => {
          console.error(erro);
        }
      );
    } else {
      console.log("parametros: ", parametros);
      setParametros(parametros);
    }
  }

  async function fetchEtapasFunil() {
    if (funil !== "" && funil !== null) {
      // const todasEtapas = await listEtapaFunilAssinante(token)
      // const etapasFunil = todasEtapas.filter(etapa => funil.etapas.includes(etapa.id))
      setEtapas(funil.etapas.sort((a, b) => a.ordem - b.ordem));
    }
  }

  useEffect(() => {
    if (!hasRun.current) {
      setLoading(true);
      fetchFunilByAssinante();
      fetchParametroAssinante();
      hasRun.current = true;
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchEtapasFunil();
  }, [funil]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    setTempValue(event.target.value);
  };

  const handleEditClick = (id, value) => {
    setEditingId(id);
    setTempValue(value);
  };

  const handleUpdateClick = async (id) => {
    let etapaSelecionada = etapas.filter((etapa) => etapa.id === id);
    etapaSelecionada[0].horas_expiracao = tempValue;
    await updEtapaFunilAssinante(token, etapaSelecionada[0]).then(
      (resp) => {
        console.log("etapa atualizada: ", resp);
        toast.success("Etapa atualizada com sucesso!");
      },
      (erro) => {
        toast.error(`Ocorreu um erro ao atualizar a etapa: ${erro}`);
      }
    );

    setEditingId(null);
  };

  const handleCancelClick = () => {
    setTempValue("");
    setEditingId(null);
  };

  return (
    <>
      <HomeBar
        nome={nome}
        token={token}
        profile={profile}
        isAuthenticated={isAuthenticated}
      />
      <Container sx={{ marginTop: 5, paddingTop: "69px" }}>
        {loading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="ajustes">
              <Tab label="Etapas" {...a11yProps(0)} />
              <Tab label="Outros ajustes" {...a11yProps(1)} />
            </Tabs>
          </Box>
        </Box>
        <LightCRMPanel value={value} index={0}>
          {funil !== "" && funil !== null ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="etapas">
                <TableHead>
                  <TableRow>
                    <TableCell>Etapa</TableCell>
                    <TableCell align="right">Expira em</TableCell>
                    <TableCell align="center">Ação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {etapas.map((etapa) => (
                    <TableRow
                      key={etapa.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {etapa.nome}
                      </TableCell>
                      <TableCell align="right">
                        {editingId === etapa.id ? (
                          <TextField
                            value={tempValue}
                            onChange={handleInputChange}
                            size="small"
                            type="number"
                          />
                        ) : (
                          <span
                            style={{ cursor: "pointer", color: "blue" }}
                            onClick={() =>
                              handleEditClick(etapa.id, etapa.horas_expiracao)
                            }
                          >
                            {etapa.horas_expiracao}
                          </span>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {editingId === etapa.id && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: 1,
                            }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => handleUpdateClick(etapa.id)}
                            >
                              Atualizar
                            </Button>
                            <Button
                              variant="contained"
                              color="error"
                              size="small"
                              onClick={() => handleCancelClick()}
                            >
                              Cancelar
                            </Button>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>
              Ainda sem funis para o assinante! Criando...
            </Typography>
          )}
        </LightCRMPanel>
        <LightCRMPanel value={value} index={1}>
          {parametros && (
            <ParametroAssinanteCRM
              token={token}
              assinante={profile.assinante}
              parametros={parametros}
            />
          )}
        </LightCRMPanel>
      </Container>
      <Toaster toastOptions={{ duration: 10000 }} />
    </>
  );
}
