import { Box, Button, TextField } from "@mui/material";

export default function CardAgendarDlg({ handleSubmit }) {
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        // mt: 2,
        // position: 'relative',
        display: "flex",
        flexDirection: "column",
        gap: 2,
        // width: 300,
        margin: "auto",
        padding: 2,
        border: "1px solid #ccc",
        borderRadius: 2,
      }}
    >
      <TextField
        id="date"
        name="date"
        label="Data"
        type="date"
        size="small"
        InputLabelProps={{
          shrink: true,
        }}
        required
      />
      <TextField
        id="time"
        name="time"
        label="Hora"
        type="time"
        size="small"
        InputLabelProps={{
          shrink: true,
        }}
        required
      />
      <Button type="submit" variant="contained" color="primary">
        Agendar
      </Button>
    </Box>
  );
}
