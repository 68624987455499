import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import {
  memo,
  useCallback,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from "react";
import toast, { Toaster } from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import { getUserCardsList, updCard } from "../../services/SrvCard";
import CardTag from "./CardTag";
import ContatoWhats from "../Contato/ContatoWhats";
import { Search } from "@mui/icons-material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ConfirmationDialog from "../Dialogs/ConfirmationDialog";
import CardEditDlg from "./Dialogs/CardEditDlg";
import { useNavigate } from "react-router-dom";
import CardAbandonoDlg from "./Dialogs/CardAbandonoDlg";

const SearchInput = memo(({ value, onChange }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [value]);

  return (
    <TextField
      inputRef={inputRef}
      type="text"
      placeholder="Nome para pesquisar"
      variant="outlined"
      size="small"
      value={value}
      onChange={onChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
});

const CardsList = ({ token, handleMenuNavigation, setCardToFilter }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [statusOption, setStatusOption] = useState(false);
  const [selectedEtapa, setSelectedEtapa] = useState(null);
  const [showConfirmationDlg, setShowConfirmationDlg] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);
  const [updating, setUpdating] = useState(false);
  const deferredSearch = useDeferredValue(searchValue);

  const navigate = useNavigate();

  const fetchCards = useCallback(() => {
    return getUserCardsList(
      token,
      page + 1,
      rowsPerPage,
      deferredSearch.length > 2 ? deferredSearch : "",
      statusOption
    );
  }, [token, page, rowsPerPage, deferredSearch, statusOption]);

  const { data, isLoading, isError } = useQuery({
    queryKey: [
      "userCardsList",
      token,
      page,
      rowsPerPage,
      deferredSearch,
      statusOption,
    ],
    queryFn: fetchCards,
    keepPreviousData: true,
    staleTime: 5000,
    refetchInterval: 60000,
    refetchIntervalInBackground: true,
  });

  if (isLoading)
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ height: "100vh", width: "100%" }}
      >
        <CircularProgress />
      </Box>
    );

  if (isError) toast.error("Erro ao carregar cards");

  const handleActivate = async (card) => {
    // Implementar ativação
    console.log("Ativar card", card);
    setUpdating(true);
    selectedCard.status = "A";
    await updCard(token, {
      id: selectedCard.id,
      status: "A",
    }).then(
      (resp) => {
        setUpdating(false);
        setShowConfirmationDlg(false);
      },
      (erro) => {
        toast.error(`Erro ao retomar o card: ${erro.response.data.detail}`);
        setUpdating(false);
      }
    );
  };

  const handleInactivate = async (motivo) => {
    console.log("Inativar card", selectedCard);
    setUpdating(true);
    selectedCard.status = "I";
    selectedCard.motivo_abandono = motivo;
    await updCard(token, {
      id: selectedCard.id,
      status: "I",
    }).then(
      (resp) => {
        setUpdating(false);
        setShowConfirmationDlg(false);
      },
      (erro) => {
        toast.error(`Erro ao abandonar o card: ${erro.response.data.detail}`);
        setUpdating(false);
      }
    );
  };

  const handleNavigateCardClick = (card) => {
    setCardToFilter(card);
    handleMenuNavigation("funil");
    // setSelectedCard(card);
    // setSelectedEtapa(card.etapa);
  };

  const handleCloseCardDlg = () => {
    setSelectedCard(null);
    setSelectedEtapa(null);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="100%"
      width="100%"
      p={2}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          padding: 1,
          backgroundColor: "#f5f5f5",
          width: "100%",
        }}
      >
        <SearchInput
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <FormControlLabel
          control={<Checkbox />}
          label="Mostrar apenas ativos"
          checked={statusOption}
          onChange={(e) => setStatusOption(e.target.checked)}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Expiração</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Etapa</TableCell>
              <TableCell>Whatsapp</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.results.map((card) => (
              <TableRow key={card.id}>
                <TableCell>{card.id}</TableCell>
                <TableCell>
                  <CardTag
                    text={new Date(card.data_expiracao).toLocaleString(
                      "pt-BR",
                      {
                        timezone: "America/Sao_Paulo",
                      }
                    )}
                    color={
                      card.status_expiracao === "A"
                        ? "#FF0000"
                        : card.status_expiracao === "E"
                        ? "#008000"
                        : "#FFFF00"
                    }
                  />
                </TableCell>
                <TableCell>{card.contato?.nome}</TableCell>
                <TableCell>{card.etapa?.nome}</TableCell>
                <TableCell>
                  <ContatoWhats
                    token={token}
                    contatoId={card.contato?.id}
                    whatsapp={card.contato?.whatsapp}
                    card={card}
                  />
                </TableCell>
                <TableCell>
                  {card.status === "I"
                    ? "Abandonado"
                    : card.status === "A"
                    ? "Ativo"
                    : "Concluído"}
                </TableCell>
                <TableCell>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    gap={1}
                  >
                    {card.status === "I" && (
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={() => {
                          setConfirmationTitle("Ativar card");
                          setConfirmationMessage(
                            "Deseja realmente ativar o card?"
                          );
                          setSelectedCard(card);
                          setShowConfirmationDlg(true);
                        }}
                        sx={{ width: 100, alignSelf: "center" }}
                      >
                        {card === selectedCard && updating ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          "Retomar"
                        )}
                      </Button>
                    )}
                    {card.status === "A" && (
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() => {
                          setConfirmationTitle("Abandonar card");
                          setConfirmationMessage(
                            "Deseja realmente abandonar o card?"
                          );
                          setSelectedCard(card);
                          setShowConfirmationDlg(true);
                        }}
                        sx={{ width: 100, alignSelf: "center" }}
                      >
                        {card === selectedCard && updating ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          "Abandonar"
                        )}
                      </Button>
                    )}
                    {card.status === "A" && (
                      <IconButton onClick={() => handleNavigateCardClick(card)}>
                        <ArrowOutwardIcon />
                      </IconButton>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={data.count}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                onPageChange={(_, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setPage(0);
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        title={confirmationTitle}
        message={confirmationMessage}
        open={showConfirmationDlg && selectedCard?.status === "I"}
        onConfirm={
          selectedCard?.status === "I" ? handleActivate : handleInactivate
        }
        onClose={() => setShowConfirmationDlg(false)}
      />
      <CardAbandonoDlg
        token={token}
        open={showConfirmationDlg && selectedCard?.status === "A"}
        handleAbandonar={handleInactivate}
        handleClose={() => setShowConfirmationDlg(false)}
      />
      {/* {selectedCard && (
        <CardEditDlg
          token={token}
          card={selectedCard}
          etapa={selectedEtapa}
          open={selectedCard !== null}
          onClose={handleCloseCardDlg}
        />
      )} */}
      <Toaster />
    </Box>
  );
};

export default CardsList;
