import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import CelularEdit from "../CelularEdit";
import EmailIcon from "@mui/icons-material/Email";
import AlarmIcon from "@mui/icons-material/Alarm";
import { useEffect, useState } from "react";
import WhatsAppLink from "../../WhatsAppLink";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DangerousIcon from "@mui/icons-material/Dangerous";
import CardFileUploadDlg from "./CardFileUploadDlg";
import toast, { Toaster } from "react-hot-toast";
import { getCardFiles, updCard } from "../../../services/SrvCard";
import { updContato } from "../../../services/SrvContato";
import CardAgendarDlg from "./CardAgendarDlg";
import CheckListAbordagem from "../../CheckListAbordagem";
import CurrencyEditableLabel from "../../CurrencyEditableLabel";
import ConfirmationDialog from "../../Dialogs/ConfirmationDialog";
import CardAbandonoDlg from "./CardAbandonoDlg";

const CardEditDlg = ({ token, card, etapa, open, onClose }) => {
  const baseAPI = process.env.REACT_APP_BASE_API_URL;

  const [formValues, setFormValues] = useState({
    whatsapp: card.contato ? card.contato.whatsapp : "",
    email: card.contato ? card.contato.email : "",
    observacoes: card.observacoes ? card.observacoes : "",
    rating: card.rating,
  });

  const [oldValues, setOldValues] = useState({
    whatsapp: card.contato ? card.contato.whatsapp : "",
    email: card.contato ? card.contato.email : "",
    observacoes: card.observacoes ? card.observacoes : "",
  });

  const [tagColor, setTagColor] = useState(
    card.status_expiracao === "A"
      ? "red"
      : card.status_expiracao === "E"
      ? "green"
      : "yellow"
  );

  const [editingWhatsapp, setEditingWhatsapp] = useState(false);
  const [editingEmail, setEditingEmail] = useState(false);
  const [showUploadComponent, setShowUploadComponent] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [updatingWhatsapp, setUpdatingWhatsapp] = useState(false);
  const [updatingEmail, setUpdatingEmail] = useState(false);
  const [updatingObservacoes, setUpdatingObservacoes] = useState(false);
  const [agendar, setAgendar] = useState(false);
  const [valorContrato, setValorContrato] = useState(card.valor_contrato);
  const [showAbandonoDlg, setShowAbandonoDlg] = useState(false);

  const valorado = card.valor_contrato !== "0.00";
  // console.log("card", card);

  useEffect(() => {
    setTagColor(
      card.status_expiracao === "A"
        ? "red"
        : card.status_expiracao === "E"
        ? "green"
        : "yellow"
    );
  }, [card.status_expiracao]);

  const handleChange = async (event) => {
    // console.log("handleChange");
    // console.log("event.target", event.target);
    const { name, value } = event.target;
    if (name === "rating") {
      updCard(token, {
        id: card.id,
        originalId: card.originalId,
        rating: parseInt(value),
      }).then(
        (resp) => {
          setFormValues({
            ...formValues,
            [name]: parseInt(value),
          });
          card.rating = parseInt(value);
        },
        (erro) => {
          toast.error(`Erro ao atualizar o card: ${erro.response.data.detail}`);
        }
      );
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleUploadDialogClose = async () => {
    setShowUploadComponent(false);
    setUpdating(true);
    await getCardFiles(card.originalId, token).then(
      (resp) => {
        console.log(resp);
        card.files = resp;
      },
      (erro) => {
        toast.error(`Erro ao atualizar o card: ${erro.response.data.detail}`);
      }
    );
    setUpdating(false);
  };

  const updateWhatsapp = async () => {
    setUpdatingWhatsapp(true);
    let whatsapp = formValues.whatsapp.replace(/[^0-9]/g, "");
    whatsapp = parseInt(whatsapp);
    await updContato(
      {
        id: card.contato.id,
        whatsapp: whatsapp,
      },
      token
    ).then(
      (resp) => {
        card.contato.whatsapp = formValues.whatsapp;
        setEditingWhatsapp(false);
        setUpdatingWhatsapp(false);
      },
      (erro) => {
        toast.error(`Erro ao atualizar o card: ${erro.response.data.detail}`);
        setUpdatingWhatsapp(false);
      }
    );
  };

  const updateEmail = async () => {
    setUpdatingEmail(true);
    await updContato(
      {
        id: card.contato.id,
        email: formValues.email,
      },
      token
    ).then(
      (resp) => {
        card.contato.email = formValues.email;
        setEditingEmail(false);
        setUpdatingEmail(false);
      },
      (erro) => {
        toast.error(`Erro ao atualizar o card: ${erro.response.data.detail}`);
        setUpdatingEmail(false);
      }
    );
  };

  const updateObservacoes = async () => {
    setUpdating(true);
    await updCard(token, {
      id: card.id,
      originalId: card.originalId,
      observacoes: formValues.observacoes,
    }).then(
      (resp) => {
        card.observacoes = formValues.observacoes;
        setUpdating(false);
        setUpdatingObservacoes(false);
      },
      (erro) => {
        toast.error(`Erro ao atualizar o card: ${erro.response.data.detail}`);
        setUpdating(false);
        setUpdatingObservacoes(false);
      }
    );
  };

  const updateDataReuniao = async (dateTime) => {
    setUpdating(true);
    await updCard(token, {
      id: card.id,
      originalId: card.originalId,
      data_expiracao: dateTime,
    }).then(
      (response) => {
        card.data_expiracao = dateTime;
        card.status_expiracao = "E";
        setAgendar(false);
        setUpdating(false);
      },
      (error) => {
        toast.error(`Erro ao atualizar a data da reunião: ${error}`);
        setUpdating(false);
      }
    );
  };

  const handleDataReuniaoSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const date = data.get("date");
    const time = data.get("time");

    // Combine date and time to create a Date object
    const dateTimeString = `${date}T${time}`;
    const dateTime = new Date(dateTimeString);

    if (dateTime < new Date()) {
      toast.error("Data e hora devem ser futuras");
      return;
    }

    updateDataReuniao(dateTime);
  };

  const handleAbordagensChange = async (value) => {
    setUpdating(true);
    card.abordagens = value;
    await updCard(token, {
      id: card.id,
      originalId: card.originalId,
      abordagens: value,
    }).then(
      (resp) => {
        setUpdating(false);
      },
      (erro) => {
        toast.error(`Erro ao atualizar o card: ${erro.response.data.detail}`);
        setUpdating(false);
      }
    );
  };

  const updateValorContrato = async () => {
    setUpdating(true);
    card.valor_contrato = valorContrato;
    await updCard(token, {
      id: card.id,
      originalId: card.originalId,
      valor_contrato: valorContrato,
    }).then(
      (resp) => {
        setUpdating(false);
      },
      (erro) => {
        toast.error(`Erro ao atualizar o card: ${erro.response.data.detail}`);
        setUpdating(false);
      }
    );
  };

  const handleAbandonarCard = async (motivo) => {
    setUpdating(true);
    card.status = "I";
    await updCard(token, {
      id: card.id,
      originalId: card.originalId,
      status: "I",
      motivo_abandono: motivo,
    }).then(
      (resp) => {
        setUpdating(false);
        setShowAbandonoDlg(false);
        onClose();
      },
      (erro) => {
        toast.error(`Erro ao abandonar o card: ${erro.response.data.detail}`);
        setUpdating(false);
      }
    );
  };

  const disabled = card.status === "A" ? false : true;
  // console.log("etapa", etapa);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ color: "blue" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <Typography variant="h6">{card.contato.nome}</Typography>
          <Rating
            id="rating"
            name="rating"
            value={formValues.rating}
            onChange={handleChange}
            max={3}
          />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
          {editingWhatsapp ? (
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              <CelularEdit
                label="WhatsApp"
                id="whatsapp"
                name="whatsapp"
                variant="standard"
                fullWidth
                required
                value={formValues.whatsapp}
                onChange={handleChange}
                onBlur={() => updateWhatsapp()}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    updateWhatsapp(false);
                    return;
                  }
                  if (event.key === "Escape") {
                    formValues.whatsapp = oldValues.whatsapp;
                    setEditingWhatsapp(false);
                  }
                }}
              />
              {updatingWhatsapp && (
                <CircularProgress color="inherit" size={20} />
              )}
            </Box>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              <Typography
                onClick={() => {
                  setOldValues({ ...oldValues, whatsapp: formValues.whatsapp });
                  setEditingWhatsapp(true);
                }}
              >
                {card.contato?.whatsapp}
              </Typography>
              <WhatsAppLink phoneNumber={card.contato?.whatsapp} />
            </Box>
          )}
          {editingEmail || formValues.email === "" ? (
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              <TextField
                label="Email"
                id="email"
                name="email"
                variant="standard"
                type="email"
                InputProps={{
                  startAdornment: (
                    <EmailIcon sx={{ color: "action.active", mr: 1 }} />
                  ),
                }}
                fullWidth
                onChange={handleChange}
                value={formValues.email}
                onBlur={() => updateEmail()}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    updateEmail();
                    return;
                  }
                  if (event.key === "Escape") {
                    formValues.email = oldValues.email;
                    setEditingEmail(false);
                  }
                }}
              />
              {updatingEmail && <CircularProgress color="inherit" size={20} />}
            </Box>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              <Typography
                onClick={() => {
                  setOldValues({ ...oldValues, email: formValues.email });
                  setEditingEmail(true);
                }}
              >
                {formValues.email}
              </Typography>
              <IconButton
                // color="primary"
                component="a"
                href={`mailto:${formValues.email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <EmailIcon sx={{ color: "action.active" }} />
              </IconButton>
            </Box>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", gap: 3 }}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={updating}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              padding: 2,
              boxShadow: "inset 0px 2px 5px rgba(0, 0, 0, 0.2)",
              borderRadius: 2,
            }}
          >
            <Typography variant="subtitle2">
              {`Movido para `}
              <strong>{`${etapa.nome}`}</strong>
              {` em ${new Date(card.data_lista).toLocaleString("pt-BR", {
                timezone: "America/Sao_Paulo",
              })}`}
            </Typography>
            {etapa.exibe_calendario && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "1px solid",
                    borderColor: tagColor,
                    padding: 1.5,
                    borderRadius: 2,
                    width: "100%",
                    mb: 2,
                    transition: "border 0.3s ease",
                    "&:hover": {
                      border: "2px solid",
                      borderColor: tagColor,
                    },
                  }}
                >
                  <Typography variant="caption">Agendado para:</Typography>
                  <Typography variant="caption">
                    {new Date(card.data_expiracao).toLocaleString("pt-BR", {
                      timezone: "America/Sao_Paulo",
                    })}
                  </Typography>
                  <Button
                    variant="outlined"
                    startIcon={<AlarmIcon />}
                    onClick={() => setAgendar(true)}
                  >
                    Reagendar
                  </Button>
                </Box>
                {agendar && (
                  <CardAgendarDlg handleSubmit={handleDataReuniaoSubmit} />
                )}
              </>
            )}
            {etapa.exige_valor && (
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  border: "1px solid",
                  borderColor: tagColor,
                  padding: 1.5,
                  borderRadius: 2,
                  width: "100%",
                  mb: 2,
                  transition: "border 0.3s ease",
                  "&:hover": {
                    border: "2px solid",
                    borderColor: tagColor,
                  },
                }}
              >
                <CurrencyEditableLabel
                  initialText={valorContrato}
                  onChange={(value) => setValorContrato(value)}
                  onConfirm={updateValorContrato}
                />
              </Box>
            )}
            {etapa.mostra_tentativas && (
              <Box
                display="flex"
                // justifyContent="space-between"
                alignItems="center"
                // sx={{ mb: 2 }}
              >
                <Typography variant="subtitle2">Tentativas:</Typography>
                <CheckListAbordagem
                  abordagens={card.abordagens}
                  onAbordagensChange={handleAbordagensChange}
                  disabled={disabled}
                />
              </Box>
            )}
            <Typography sx={{ fontWeight: "bold" }}>Anotações</Typography>
            <TextField
              name="observacoes"
              id="observacoes"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              value={formValues.observacoes}
              onChange={handleChange}
              sx={{ fontSize: 10 }}
              onBlur={() => {
                if (updatingObservacoes) {
                  updateObservacoes();
                }
              }}
              onKeyPress={(event) => {
                // console.log("onKeyPress");
                if (event.key === "Enter") {
                  // console.log("Enter");
                  updateObservacoes();
                  return;
                }
                if (event.key === "Escape") {
                  // console.log("Escape");
                  formValues.observacoes = oldValues.observacoes;
                  setUpdatingObservacoes(false);
                } else {
                  // console.log("else");
                  if (!updatingObservacoes) {
                    setUpdatingObservacoes(true);
                    setOldValues({
                      ...oldValues,
                      observacoes: formValues.observacoes,
                    });
                  }
                }
              }}
            />

            {card.files?.length > 0 && (
              <Accordion sx={{ marginTop: 2 }}>
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="anexos-content"
                  id="anexos-header"
                >
                  <Typography variant="caption">Anexos</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {card.files.map((file, index) => (
                      <ListItem key={index}>
                        <Link
                          href={`${baseAPI}${file.file_url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <ListItemText
                            primaryTypographyProps={{ variant: "caption" }}
                            primary={file.file_url.split("/").pop()}
                          />
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            )}
            <CardFileUploadDlg
              show={showUploadComponent}
              handleClose={handleUploadDialogClose}
              cardId={card.originalId}
              token={token}
            />
            <Toaster toastOptions={{ duration: 3000 }} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
              minWidth: 200,
            }}
          >
            <Button
              component="label"
              role={undefined}
              variant="contained"
              fullWidth
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              onClick={() => setShowUploadComponent(true)}
            >
              Anexar arquivo
            </Button>
            <Button
              variant="contained"
              startIcon={<DangerousIcon />}
              fullWidth
              onClick={() => setShowAbandonoDlg(true)}
            >
              Abandonar Card
            </Button>
            <CardAbandonoDlg
              token={token}
              open={showAbandonoDlg}
              handleAbandonar={handleAbandonarCard}
              handleClose={() => setShowAbandonoDlg(false)}
            />
            {/* <ConfirmationDialog
              title={"Abandonar Card"}
              message={"Deseja realmente abandonar este card?"}
              open={showAbandonoDlg}
              onConfirm={handleAbandonarCard}
              onClose={() => setShowAbandonoDlg(false)}
            /> */}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CardEditDlg;
