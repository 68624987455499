import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  Paper,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import BlockIcon from "@mui/icons-material/Block";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { getSetor, getSetores } from "../../services/SrvSetor";
import toast, { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";
import TemplateListaFunilForm from "./TemplateListaFunilForm";
import { delLista } from "../../services/SrvTemplateFunil";

export default function TemplateListaFunil({ token, etapa, fetchEtapas }) {
  const [setores, setSetores] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showDel, setShowDel] = useState(false);

  const addSetorLista = (novoSetor) => {
    setSetores((prevSetores) =>
      prevSetores.includes(novoSetor)
        ? prevSetores
        : [...prevSetores, novoSetor]
    );
  };

  const fetchSetores = async () => {
    await getSetores(token).then(
      (resp) => {
        setSetores(resp.filter((setor) => etapa.setores.includes(setor.id)));
      },
      (erro) => {
        toast.error(`Ocorreu um erro ao recuperar os setores: ${erro}`);
      }
    );
  };

  useEffect(() => {
    fetchSetores();
    // eslint-disable-next-line
  }, [etapa]);

  const handleDeleteEtapa = async () => {
    if (etapa) {
      await delLista(token, etapa.id).then(
        (resp) => {
          toast.success("Etapa excluída com sucesso!");
          handleDelClose(true);
        },
        (erro) => {
          const resp = erro.response;
          console.log(resp.data);
          toast.error(`Ocorreu um erro ao excluir a etapa: ${resp.data.error}`);
          handleDelClose(true);
        }
      );
    }
  };

  const handleEditClick = () => {
    setShowForm(true);
  };

  const handleDeleteClick = () => {
    setShowDel(true);
  };

  const handleClose = () => {
    setShowForm(false);
  };

  const handleDelClose = () => {
    setShowDel(false);
  };

  return (
    <Box
      component={Paper}
      elevation={5}
      sx={{
        border: "1px solid",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F8F8FF",
        width: 250,
        padding: 1,
        maxHeight: 350,
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {etapa.nome}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <IconButton color="primary" onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
          <IconButton color="primary" onClick={handleDeleteClick}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>

      <Divider component="li" sx={{ mt: 1 }} />
      <Box sx={{ display: "flex", flexDirection: "row", gap: 1, mt: 1 }}>
        <Typography variant="body2">Expira em</Typography>
        <Typography variant="subtitle2">{etapa.horas_expiracao}</Typography>
        <Typography variant="body2">horas</Typography>
        {etapa.horas_uteis && <Typography variant="body2">úteis</Typography>}
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="body2">Permite entrada</Typography>
        {etapa.permite_entrada ? (
          <CheckIcon color="success" />
        ) : (
          <BlockIcon color="error" />
        )}
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="body2">Mostra tentativas</Typography>
        {etapa.mostra_abordagens ? (
          <CheckIcon color="success" />
        ) : (
          <BlockIcon color="error" />
        )}
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="body2">Exibe calendário</Typography>
        {etapa.exibe_calendario ? (
          <CheckIcon color="success" />
        ) : (
          <BlockIcon color="error" />
        )}
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="body2">Exige valor</Typography>
        {etapa.exige_valor ? (
          <CheckIcon color="success" />
        ) : (
          <BlockIcon color="error" />
        )}
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="body2">Conclui card</Typography>
        {etapa.conclui_card ? (
          <CheckIcon color="success" />
        ) : (
          <BlockIcon color="error" />
        )}
      </Box>
      <Box display="flex" flexDirection="row" gap={0.5}>
        <Typography variant="body2">Ordem:</Typography>
        <Typography variant="subtitle2">{etapa.ordem}</Typography>
      </Box>
      {setores.length > 0 && (
        <>
          <Divider component="li" sx={{ mt: 1 }} />
          <Box display="flex" flexDirection="column" gap={0.5} marginTop={1}>
            <Typography variant="subtitle2">
              Aplicável para os seguintes setores:
            </Typography>
            <Box
              component={List}
              sx={{ display: "flex", flexDirection: "column", columnGap: 0.25 }}
            >
              {setores.map((setor, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <ArrowRightIcon color="primary" />
                  <Typography variant="body2">{setor?.nome}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </>
      )}

      <Toaster />
      <TemplateListaFunilForm
        token={token}
        etapa={etapa}
        show={showForm}
        handleClose={handleClose}
        fetchEtapas={fetchEtapas}
      />
      <Dialog open={showDel}>
        <DialogTitle>Exclusão</DialogTitle>
        <DialogContent>
          {`Confirma a exclusão da etapa ${etapa?.nome || ""}?`}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(event) => {
              handleDelClose(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={(event) => {
              handleDeleteEtapa();
            }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
