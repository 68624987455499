import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { listMotivoAbandono } from "../../../services/SrvCard";

export default function CardAbandonoDlg({
  token,
  open,
  handleAbandonar,
  handleClose,
}) {
  const [motivoAbandono, setMotivoAbandono] = useState("");
  const [motivosAbandono, setMotivosAbandono] = useState([]);
  const [error, setError] = useState(false);

  const fetchMotivosAbandono = async () => {
    const motivos = await listMotivoAbandono(token);
    setMotivosAbandono(motivos);
  };

  useEffect(() => {
    fetchMotivosAbandono();
  }, []);

  const handleConfirm = () => {
    if (!motivoAbandono) {
      setError(true);
      return;
    }
    handleAbandonar(motivoAbandono);
    setError(false);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Por que abandonar?</DialogTitle>
      <DialogContent>
        <TextField
          select
          label="Motivo"
          value={motivoAbandono}
          variant="standard"
          onChange={(e) => {
            setMotivoAbandono(e.target.value);
            if (e.target.value) setError(false);
          }}
          fullWidth
          error={error}
          helperText={error ? "Por favor, selecione um motivo." : ""}
        >
          {motivosAbandono.map((motivo) => (
            <MenuItem key={motivo.id} value={motivo.id}>
              {motivo.motivo}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            setMotivoAbandono("");
            setError(false);
            handleClose();
          }}
          color="primary"
        >
          Cancelar
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Abandonar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
