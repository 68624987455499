const colors = {
  AliceBlue: { hex: "#F0F8FF", rgb: "(240,248,255)" },
  GhostWhite: { hex: "#F8F8FF", rgb: "(248,248,255)" },
  Snow: { hex: "#FFFAFA", rgb: "(255,250,250)" },
  Seashell: { hex: "#FFF5EE", rgb: "(255,245,238)" },
  FloralWhite: { hex: "#FFFAF0", rgb: "(255,250,240)" },
  WhiteSmoke: { hex: "#F5F5F5", rgb: "(245,245,245)" },
  Beige: { hex: "#F5F5DC", rgb: "(245,245,220)" },
  OldLace: { hex: "#FDF5E6", rgb: "(253,245,230)" },
  Ivory: { hex: "#FFFFF0", rgb: "(255,255,240)" },
  Linen: { hex: "#FAF0E6", rgb: "(250,240,230)" },
  Cornsilk: { hex: "#FFF8DC", rgb: "(255,248,220)" },
  AntiqueWhite: { hex: "#FAEBD7", rgb: "(250,235,215)" },
  BlanchedAlmond: { hex: "#FFEBCD", rgb: "(255,235,205)" },
  Bisque: { hex: "#FFE4C4", rgb: "(255,228,196)" },
  LightYellow: { hex: "#FFFFE0", rgb: "(255,255,224)" },
  LemonChiffon: { hex: "#FFFACD", rgb: "(255,250,205)" },
  LightGoldenrodYellow: { hex: "#FAFAD2", rgb: "(250,250,210)" },
  PapayaWhip: { hex: "#FFEFD5", rgb: "(255,239,213)" },
  PeachPuff: { hex: "#FFDAB9", rgb: "(255,218,185)" },
  Moccasin: { hex: "#FFE4B5", rgb: "(255,228,181)" },
  PaleGoldenrod: { hex: "#EEE8AA", rgb: "(238,232,170)" },
  MistyRose: { hex: "#FFE4E1", rgb: "(255,228,225)" },
  LavenderBlush: { hex: "#FFF0F5", rgb: "(255,240,245)" },
  Lavender: { hex: "#E6E6FA", rgb: "(230,230,250)" },
  Thistle: { hex: "#D8BFD8", rgb: "(216,191,216)" },
  Azure: { hex: "#F0FFFF", rgb: "(240,255,255)" },
  LightCyan: { hex: "#E0FFFF", rgb: "(224,255,255)" },
  PowderBlue: { hex: "#B0E0E6", rgb: "(176,224,230)" },
  PaleTurquoise: { hex: "#AFEEEE", rgb: "(175,238,238)" },
  Honeydew: { hex: "#F0FFF0", rgb: "(240,255,240)" },
  MintCream: { hex: "#F5FFFA", rgb: "(245,255,250)" },
};

function getRandomColors(n) {
  const colorKeys = Object.keys(colors);
  if (n > colorKeys.length) {
    throw new Error(
      "O valor de 'n' excede o número total de cores disponíveis."
    );
  }
  const shuffled = colorKeys.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, n).map((key) => ({ name: key, ...colors[key] }));
}

export { getRandomColors };
