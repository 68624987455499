import styled from "@emotion/styled";
import CardTag from "./CardTag";
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { Draggable } from "@hello-pangea/dnd";
import NumberedStar from "./NumberedStar";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import NotesIcon from "@mui/icons-material/Notes";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CardAgendarDlg from "./Dialogs/CardAgendarDlg";
import CardValorarDlg from "./Dialogs/CardValorarDlg";
import { useEffect, useState } from "react";
import { updCard } from "../../services/SrvCard";
import toast from "react-hot-toast";
import WhatsAppLink from "../WhatsAppLink";

const Container = styled.div`
  padding: 8px;
  border: ${(props) =>
    props.isDragging ? "2px solid black" : "1px solid lightgray"};
  border-radius: 5px;
  margin-bottom: 2px;
  display: flex;
  flexgrow: 1;
  //   align-items: center;
  //   justify-content: center;
  background-color: ${(props) =>
    props.disabled ? "lightgray" : props.isDragging ? "lightgray" : "white"};
  display: flex;
  flex-direction: column;
  margin: 4px;
  gap: 5px;
  cursor: move;
`;

const SimpleCard = ({ token, index, card, etapa, setOpenEditDlg }) => {
  const dataExpiracao = new Date(card.data_expiracao);
  const dataToShow = dataExpiracao.toLocaleString("pt-BR", {
    timezone: "America/Sao_Paulo",
  });

  const dataLista = new Date(card.data_lista);
  let agendar = false;
  if (!isNaN(dataLista) && !isNaN(dataExpiracao)) {
    const dataListaISO = dataLista.toISOString();
    const dataExpiracaoISO = dataExpiracao.toISOString();
    agendar =
      dataListaISO === dataExpiracaoISO && etapa.exibe_calendario === true;
  }

  const valorar = etapa.exige_valor && card.valor_contrato === "0.00";
  const valorado = card.valor_contrato !== "0.00";

  const tagColor =
    card.status_expiracao === "A"
      ? "#FF0000"
      : card.status_expiracao === "E"
      ? "#008000"
      : "#FFFF00";

  const [updating, setUpdating] = useState(false);
  const [valorContrato, setValorContrato] = useState(card.valor_contrato);
  const [disabled, setDisabled] = useState(card.status === "I");

  useEffect(() => {
    setDisabled(card.status === "I");
  }, [card.status]);

  const updateDataReuniao = async (dateTime) => {
    setUpdating(true);
    await updCard(token, {
      id: card.id,
      originalId: card.originalId,
      data_expiracao: dateTime,
    }).then(
      (response) => {
        card.data_expiracao = dateTime;
        card.status_expiracao = "E";
        setUpdating(false);
      },
      (error) => {
        toast.error(`Erro ao atualizar a data da reunião: ${error}`);
        setUpdating(false);
      }
    );
  };

  const handleDataReuniaoSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const date = data.get("date");
    const time = data.get("time");

    // Combine date and time to create a Date object
    const dateTimeString = `${date}T${time}`;
    const dateTime = new Date(dateTimeString);

    if (dateTime < new Date()) {
      toast.error("Data e hora devem ser futuras");
      return;
    }

    updateDataReuniao(dateTime);
  };

  const updateValorContrato = async () => {
    setUpdating(true);

    await updCard(token, {
      id: card.id,
      originalId: card.originalId,
      valor_contrato: valorContrato,
    }).then(
      (response) => {
        card.valor_contrato = valorContrato;
        setUpdating(false);
      },
      (error) => {
        toast.error(`Erro ao atualizar o valor do contrato: ${error}`);
      }
    );
    setUpdating(false);
  };

  const handleValorFormSubmit = (event) => {
    event.preventDefault();
    updateValorContrato();
  };

  return (
    <Draggable draggableId={card.id} index={index}>
      {(provided, snapshot) => (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
          disabled={disabled}
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={updating}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <CardTag text={dataToShow} color={tagColor} />
            <WhatsAppLink
              phoneNumber={card.contato?.whatsapp}
              disabled={disabled}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography
              onClick={() => {
                if (!disabled) setOpenEditDlg(card, etapa);
              }}
            >
              {card.contato?.nome}
            </Typography>
            {card.rating > 0 && (
              <NumberedStar number={card.rating} size="small" />
            )}
          </Box>
          <Box>
            {agendar && (
              <CardAgendarDlg handleSubmit={handleDataReuniaoSubmit} />
            )}
            {valorar && (
              <CardValorarDlg
                handleSubmit={handleValorFormSubmit}
                setValor={setValorContrato}
              />
            )}
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems={"center"}
              sx={{ gap: 0.5 }}
            >
              {valorado && <AttachMoneyIcon sx={{ color: "action.active" }} />}
              {card.observacoes && (
                <NotesIcon sx={{ color: "action.active" }} />
              )}
              {card.files?.length > 0 && (
                <AttachFileIcon
                  fontSize="small"
                  sx={{ color: "action.active" }}
                />
              )}
            </Box>
            <Typography variant="caption">{card.contato?.cidade}</Typography>
          </Box>
        </Container>
      )}
    </Draggable>
  );
};

export default SimpleCard;
