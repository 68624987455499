import { Box, Button } from "@mui/material";
import CurrencyTextField from "../../CurrencyTextField";

export default function CardValorarDlg({ handleSubmit, setValor }) {
  function handleValorFormChange(event) {
    setValor(event.target.value);
  }
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        mt: 2,
        mb: 2,
        // position: 'relative',
        display: "flex",
        flexDirection: "column",
        gap: 2,
        // width: 300,
        // margin: 'auto',
        padding: 2,
        border: "1px solid #ccc",
        borderRadius: 2,
      }}
    >
      <CurrencyTextField
        label="Valor do Contrato"
        value="0.00"
        variant="outlined"
        id="valor_contrato"
        onChange={handleValorFormChange}
        required
      />
      <Button type="submit" variant="contained" color="primary">
        Informar
      </Button>
    </Box>
  );
}
