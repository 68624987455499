import { Box, LinearProgress } from "@mui/material";

const BarraProporcional = ({ statusCounts, maxSize = null }) => {
  const total = statusCounts.A + statusCounts.E + statusCounts.P;

  if (total === 0) {
    return (
      <LinearProgress
        variant="determinate"
        value={0}
        sx={{ height: 10, borderRadius: 5 }}
      />
    );
  }

  const percentuais = {
    A: (statusCounts.A / total) * 100,
    E: (statusCounts.E / total) * 100,
    P: (statusCounts.P / total) * 100,
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: maxSize ? maxSize : "100%",
        height: 10,
        borderRadius: 5,
        overflow: "hidden",
      }}
    >
      <Box sx={{ width: `${percentuais.A}%`, backgroundColor: "red" }} />
      <Box sx={{ width: `${percentuais.E}%`, backgroundColor: "green" }} />
      <Box sx={{ width: `${percentuais.P}%`, backgroundColor: "yellow" }} />
    </Box>
  );
};

export default BarraProporcional;
