import styled from "@emotion/styled";
// import Card from "./Card";
import { Droppable } from "@hello-pangea/dnd";
import { memo, useEffect, useState } from "react";
import SimpleCard from "./SimpleCard";
import BarraProporcional from "./BarraProporcional";
import { Box, Typography } from "@mui/material";

const Container = styled.div`
  margin: 8px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  min-width: 280px;
  max-width: 320px;

  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${({ color }) => color || "white"};
`;

const CardList = styled.div`
  // padding: 8px;
  // transition: background-color 0.2s ease;
  background-color: ${({ color }) => color || "white"};
  flex-grow: 1;
  min-height: 150px;
  align-items: stretch;
  gap: 2px;
`;

const Etapa = memo(function Etapa({
  etapa,
  cards,
  token,
  // setCard,
  // removeCard,
  setOpenEditDlg,
  limite = 0,
  color,
}) {
  const contarStatus = () => {
    let contagem = {
      A: 0,
      E: 0,
      P: 0,
    };

    cards.forEach((card) => {
      contagem[card.status_expiracao] += 1;
    });

    return contagem;
  };

  // console.log("color", color.name);

  const [contagem, setContagem] = useState(contarStatus());

  useEffect(() => {
    setContagem(contarStatus());
    // eslint-disable-next-line
  }, [cards]);

  return (
    <Container color={color.hex}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          padding: 2,
        }}
      >
        <Typography variant="h6" sx={{ alignSelf: "center" }}>
          {etapa.nome}
        </Typography>
        <Typography variant="subtitle2">{`${cards.length} cards`}</Typography>
        {limite > 0 && (
          <Typography variant="caption">{`Mostrando ${limite} cards`}</Typography>
        )}
        <BarraProporcional statusCounts={contagem} />
      </Box>
      <Droppable droppableId={etapa.id}>
        {(provided, snapshot) => (
          <CardList
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
            color={color.hex}
          >
            {limite > 0
              ? cards.slice(0, limite).map((card, index) => (
                  <SimpleCard
                    token={token}
                    key={card.id}
                    index={index}
                    card={card}
                    etapa={etapa}
                    setOpenEditDlg={setOpenEditDlg}
                  />
                  // <Card
                  //   key={card.id}
                  //   card={card}
                  //   etapa={etapa}
                  //   index={index}
                  //   token={token}
                  //   setCard={setCard}
                  //   removeCard={removeCard}
                  //   onStartUpdate={canStartUpdate}
                  //   onFinishUpdate={finishUpdate}
                  // />
                ))
              : cards.map((card, index) => (
                  <SimpleCard
                    token={token}
                    key={card.id}
                    index={index}
                    card={card}
                    etapa={etapa}
                    setOpenEditDlg={setOpenEditDlg}
                  />
                ))}
            {provided.placeholder}
          </CardList>
        )}
      </Droppable>
    </Container>
  );
});

export default Etapa;
