import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
// import GoogleLogo from '../images/web_neutral_rd_na.svg'
import { useNavigate } from "react-router-dom";
import LightCRMLogo from "../images/octupuslogo-semfundo-roxo.png";
import { getHello, setLogin } from "../services/SrvLogin";
import toast, { Toaster } from "react-hot-toast";

function Login() {
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(data.entries());

    // console.log('formJson: ', formJson)

    setLogin(formJson["email"], formJson["senha"]).then(
      (response) => {
        const token = response["token"];
        // const profile = getHello(token)

        toast.success("Login efetuado com sucesso!");
        getHello(token).then(
          (resp_hello) => {
            localStorage.removeItem("lastUpdate");
            localStorage.setItem("sessionStart", resp_hello["server_time"]);
            navigate("/", {
              state: {
                token: token,
                profile: resp_hello,
                isAuthenticated: true,
              },
            });
          },
          (error_hello) => {
            // console.log('erro_hello', error_hello);
            toast.error(`Erro ao recuperar profile do usuário: ${error_hello}`);
          }
        );
      },
      (error) => {
        toast.error(
          `Erro ao efetuar login: ${error.response.data.non_field_errors[0]}`
        );
        // console.log('erro: ', error)
      }
    );
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          border: "solid 2px",
          borderRadius: 5,
          padding: 2,
        }}
      >
        <Box
          component="img"
          src={LightCRMLogo}
          sx={{ marginTop: 2, marginBottom: 2, width: 224, height: 224 }}
        />
        {/* <Typography component="h1" variant="h5">
          Entrar
        </Typography> */}
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            type="email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="senha"
            label="Senha"
            type="password"
            id="senha"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Ir
          </Button>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link href="/register" variant="body2">
              <Typography variant="caption" display="block" gutterBottom>
                Não tem cadastro? Cadastre-se!
              </Typography>
            </Link>
            <Link href="/esqueci" variant="body2">
              <Typography variant="caption" display="block" gutterBottom>
                Esqueci a senha
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
      <Toaster toastOptions={{ duration: 4000 }} />
    </Container>
  );
}

export default Login;
