import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

const CheckListAbordagem = ({
  maxAbordagens = 5,
  abordagens = 0,
  onAbordagensChange,
  disabled = false,
}) => {
  const [checks, setChecks] = useState(abordagens);

  const handleAbordagem = (index) => {
    const novaAbordagem = index + 1;
    setChecks(novaAbordagem);
    if (onAbordagensChange) {
      onAbordagensChange(novaAbordagem);
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "space-around" }}>
      {[...Array(maxAbordagens)].map((_, index) => (
        <IconButton
          key={index}
          onClick={() => handleAbordagem(index)}
          disabled={disabled}
        >
          {index < checks ? (
            <CheckCircleOutlinedIcon sx={{ width: 15, height: 15 }} />
          ) : (
            <RadioButtonUncheckedOutlinedIcon sx={{ width: 15, height: 15 }} />
          )}
        </IconButton>
      ))}
    </Box>
  );
};

export default CheckListAbordagem;
