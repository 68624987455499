import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { DragDropContext } from "@hello-pangea/dnd";
import Etapa from "./Etapa";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { getFunilAssinanteByAssinante } from "../../services/srvFunil";
import { Search } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { adicionarHorasUteis } from "../../helpers/datehelper";
import toast, { Toaster } from "react-hot-toast";
import {
  checkExpirationUpdates,
  newCard,
  updCard,
} from "../../services/SrvCard";
import CardNovoDlg from "./Dialogs/CardNovoDlg";
import { newContato } from "../../services/SrvContato";
import { safeIdleCallback } from "../../helpers/utils";
import CardEditDlg from "./Dialogs/CardEditDlg";
import BarraProporcional from "./BarraProporcional";
import { getParametroAssinante } from "../../services/SrvParametro";
import { getRandomColors } from "../../helpers/colors";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const EtapasContainer = styled.div`
  display: flex;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  padding: 8px;
  gap: 10px;
`;

const FunilDrag = React.memo(({ cardToFilter, setCardToFilter }) => {
  const [etapas, setEtapas] = useState(null);
  const [colors, setColors] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(() => {
    return localStorage.getItem("sessionStart") || null;
  });

  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [showNovo, setShowNovo] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedEtapa, setSelectedEtapa] = useState(null);
  const [parametros, setParametros] = useState(null);

  var autenticado = false;
  var chave = null;
  var perfil = null;

  const location = useLocation();
  const { state } = location;
  const hasRun = React.useRef(false);

  if (state) {
    // console.log("state: ", state);
    chave = state.token;
    perfil = state.profile;
    autenticado = state.isAuthenticated;
  }

  // console.log("perfil: ", perfil);

  async function fetchEtapas() {
    setLoading(true);
    // const funilAssinante = await getFunilAssinanteByAssinante(
    await getFunilAssinanteByAssinante(chave, perfil.assinante).then(
      (response) => {
        console.log("response: ", response);
        const funilAssinante = response;
        // console.log("funilAssinante: ", funilAssinante);
        const etapasFunil = funilAssinante.etapas;
        let cardsInitial = [];
        let etapasInitial = {
          etapas: etapasFunil.reduce((acc, etapa) => {
            const etapaId = `etapa-${etapa.id}`;
            etapa.originalId = etapa.id;
            etapa.id = etapaId;
            acc[etapaId] = {
              ...etapa,
              cardIds: etapa.cards
                .filter(
                  // (card) => card.status === "A" && card.usuario === perfil.id
                  (card) => card.status === "A"
                )
                .map((card) => {
                  card.originalId = card.id;
                  card.id = `card-${card.id}`;
                  if (card.produto) {
                    card.produto_id = card.produto.id;
                  }
                  return card.id;
                }),
            };
            cardsInitial = [
              ...cardsInitial,
              ...etapa.cards.filter(
                // (card) => card.status === "A" && card.usuario === perfil.id
                (card) => card.status === "A"
              ),
            ];
            delete acc[etapaId].cards;
            return acc;
          }, {}),
        };

        etapasInitial = {
          ...etapasInitial,
          cards: cardsInitial.reduce((acc, card) => {
            // const cardId = `card-${card.id}`
            acc[card.id] = card;
            return acc;
          }, {}),
        };

        etapasInitial = {
          ...etapasInitial,
          etapasOrder: etapasFunil
            .sort((a, b) => a.ordem - b.ordem)
            .map((etapa) => etapa.id),
        };

        // if (parametros) {
        //   // console.log("parametros: ", parametros);
        //   const limite = parametros.limite_cards_primeira_etapa;
        //   if (limite > 0) {
        //     const primeiraEtapa =
        //       etapasInitial.etapas[etapasInitial.etapasOrder[0]];
        //     primeiraEtapa.cardIds = primeiraEtapa.cardIds.slice(0, limite);
        //   }
        // }

        // console.log("etapas: ", etapasInitial);
        setEtapas(etapasInitial);
        setColors(getRandomColors(etapasInitial.etapasOrder.length));

        console.log("etapas.cards: ", etapasInitial.cards);
      },
      (erro) => {
        console.log(erro);
        toast.error(
          `Ocorreu um erro ao carregar o funil: ${erro.response.data}`
        );
      }
    );

    setLoading(false);
  }

  async function fetchParametros() {
    setLoading(true);
    // console.log("No fetchParametros");
    await getParametroAssinante(chave, perfil.assinante).then(
      (response) => {
        // console.log("response no fetchParametros: ", response);
        setParametros(response);
      },
      (erro) => {
        console.log(erro);
        toast.error(
          `Ocorreu um erro ao carregar os parâmetros: ${erro.response.data}`
        );
      }
    );
    setLoading(false);
  }

  const contarStatus = () => {
    let contagem = {
      A: 0,
      E: 0,
      P: 0,
    };

    if (etapas !== null) {
      Object.values(etapas.cards).forEach((card) => {
        contagem[card.status_expiracao] += 1;
      });
    }

    return contagem;
  };

  const [contagem, setContagem] = useState(contarStatus());

  useEffect(() => {
    if (autenticado && !hasRun.current) {
      fetchParametros();
      hasRun.current = true;
    }
  }, [autenticado]);

  useEffect(() => {
    if (parametros) {
      fetchEtapas();
    }
  }, [parametros]);

  useEffect(() => {
    setContagem(contarStatus());
  }, [etapas]);

  useEffect(() => {
    if (autenticado) {
      const checkUpdates = async () => {
        try {
          const data = await checkExpirationUpdates(chave, lastUpdate);
          // console.log("data: ", data);
          // const data = await res.json();

          if (data.updates.length > 0) {
            let newCards = etapas.cards;
            // console.log("newCards: ", newCards);
            data.updates.forEach(({ id, status }) => {
              const cardId = `card-${id}`;
              // console.log("cardId: ", cardId);
              newCards[cardId].status_expiracao = status;
            });
            setEtapas({ ...etapas, cards: newCards });
            // console.log("etapas após update: ", etapas);
          }
          // console.log("lastUpdate no useeffect: ", data.last_update);
          setLastUpdate(data.last_update);
          localStorage.setItem("lastUpdate", data.last_update);
        } catch (error) {
          console.error("Erro ao verificar atualizações: ", error);
        }
      };
      const interval = setInterval(checkUpdates, 30000);
      return () => clearInterval(interval);
    }
  }, [autenticado, lastUpdate]);

  async function setCard(card, token) {
    return updCard(token, card)
      .then((response) => {
        safeIdleCallback(() => toast.success("Cartão atualizado com sucesso!"));
      })
      .catch((error) => {
        console.error("Erro ao atualizar card:", error);
        if (error.response?.data?.status) {
          safeIdleCallback(() =>
            toast.error(
              `Ocorreu um erro ao atualizar o cartão: ${error.response.data.status}`
            )
          );
        } else {
          safeIdleCallback(() =>
            toast.error(
              `Ocorreu um erro ao atualizar o cartão: ${error.response.data.detail}`
            )
          );
        }
      })
      .finally(() => {
        if (!card.originalId) {
          card.originalId = card.id;
          card.id = `card-${card.id}`;
        }
      });
    // await updCard(token, card).then(
    //   (response) => {
    //     toast.success("Cartão atualizado com sucesso!");
    //   },
    //   (erro) => {
    //     console.log(erro);
    //     if (erro.response.data.status) {
    //       toast.error(
    //         `Ocorreu um erro ao atualizar o cartão: ${erro.response.data.status}`
    //       );
    //     } else {
    //       toast.error(
    //         `Ocorreu um erro ao atualizar o cartão: ${erro.response.data}`
    //       );
    //     }
    //   }
    // );

    // if (!card.originalId) {
    //   card.originalId = card.id;
    //   card.id = `card-${card.id}`;
    // }
  }

  const removeCard = (cardId, etapaId) => {
    const newState = { ...etapas };
    const etapa = newState.etapas[etapaId];
    etapa.cardIds = etapa.cardIds.filter((id) => id !== cardId);
    delete newState.cards[cardId];
    setEtapas(newState);
  };

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = etapas.etapas[source.droppableId];
    const finish = etapas.etapas[destination.droppableId];

    if (start === finish) {
      const newCardIds = Array.from(start.cardIds);
      newCardIds.splice(source.index, 1);
      newCardIds.splice(destination.index, 0, draggableId);

      const newEtapa = {
        ...start,
        cardIds: newCardIds,
      };

      const newState = {
        ...etapas,
        etapas: {
          ...etapas.etapas,
          [newEtapa.id]: newEtapa,
        },
      };

      setEtapas(newState);
      return;
    }

    // Movendo de uma etapa para outra
    const startCardIds = Array.from(start.cardIds);
    startCardIds.splice(source.index, 1);
    const newStart = {
      ...start,
      cardIds: startCardIds,
    };

    const finishCardIds = Array.from(finish.cardIds);
    finishCardIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      cardIds: finishCardIds,
    };

    // Atualiza os elementos em tela
    const newState = {
      ...etapas,
      etapas: {
        ...etapas.etapas,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };

    setEtapas(newState);

    // Atualiza no backend

    // setLoading(true);
    const card = etapas.cards[draggableId];
    const etapaOriginalId = finish.originalId;
    card.etapa = etapaOriginalId;
    const data_atual = new Date();
    const horas_expiracao = finish.horas_expiracao;
    const data_expiracao = adicionarHorasUteis(data_atual, horas_expiracao);
    card.data_lista = data_atual;
    card.data_expiracao = data_expiracao;
    card.contato_id = card.contato.id;
    card.etapa_id = etapaOriginalId;
    card.status_expiracao = "E";

    safeIdleCallback(() => {
      setCard(card, chave).catch((error) => {
        console.error("Erro ao atualizar card:", error);
        toast.error("Falha ao sincronizar com o servidor. Tente novamente.");
        // TODO: Desfazer a mudança no estado
      });
    });

    // setLoading(false);
  };

  const handleNovoClick = () => {
    setShowNovo(true);
  };

  const handleNovoClose = () => {
    setShowNovo(false);
  };

  async function addContato(dados) {
    const novoContato = await newContato(dados, chave).then(
      (response) => {
        return response;
      },
      (erro) => {
        const dadosErro = erro.response.data;
        var msgErro = "";
        Object.keys(dadosErro).forEach((key) => {
          msgErro += `${key}: ${dadosErro[key]}\n`;
        });
        console.log("msgErro: ", msgErro);
        console.log("Erro ao incluir contato: ", msgErro);
        toast.error(`Erro ao incluir contato: ${msgErro}`);
        return null;
      }
    );
    return novoContato;
  }

  async function addCard(novoContato, etapa, values) {
    // console.log("novoContato>>> ", novoContato);
    console.log("values>>> ", values);
    if (novoContato !== null) {
      const data_atual = new Date();
      const horas_expiracao = etapa.horas_expiracao;
      const data_expiracao = adicionarHorasUteis(data_atual, horas_expiracao);
      const dadosCard = {
        contato_id: novoContato.id,
        // contato: novoContato.id,
        etapa: values.etapa,
        etapa_id: values.etapa,
        // produto: values.produto,
        produto_id: values.produto,
        data_lista: data_atual,
        data_expiracao: data_expiracao,
        observacoes: null,
        assinante: perfil.assinante,
        usuario: perfil.id,
      };
      await newCard(chave, dadosCard).then(
        async (response) => {
          // console.log("response no newCard: ", response);
          var card = response;
          card.originalId = card.id;
          card.id = `card-${card.id}`;
          // console.log("card atualizado: ", card);
          const newState = { ...etapas };
          newState.etapas[`etapa-${values.etapa}`].cardIds.push(card.id);
          newState.cards[card.id] = card;
          setEtapas(newState);
          toast.success("Cliente adicionado com sucesso ao funil!");
        },
        (error) => {
          console.log(
            "Erro ao adicionar o potencial cliente ao funil: ",
            error
          );
          toast.error(
            `Erro ao adicionar o potencial cliente ao funil: ${error.response.data}`
          );
        }
      );
    } else {
      toast.error("Erro ao adicionar o contato!");
    }
  }

  const handleNovoSave = async (values) => {
    console.log("values no handleNovoSave: ", values);
    setLoading(true);
    let novoContato = null;
    const etapa = etapas.etapas[`etapa-${values.etapa}`];
    if (values.contato === null) {
      var whatsapp = values.whatsapp.replace(/[^0-9]/g, "");
      whatsapp = parseInt(whatsapp);
      const dados = {
        nome: values.nome,
        email: values.email,
        whatsapp: whatsapp,
        telefone: values.telefone,
        cidade: values.cidade,
        assinante: perfil.assinante,
      };
      await addContato(dados)
        .then(
          (contato) => {
            addCard(contato, etapa, values);
          },
          (erro) => {
            console.log("erro: ", erro.rsponse.data);
            toast.error(`Erro ao incluir contato: ${erro}`);
          }
        )
        .catch((error) => {
          console.log("Erro ao incluir contato:", error);
          toast.error(`Erro ao incluir contato: ${error}`);
        });
    } else {
      novoContato = values.contato;
      const novoCard = await addCard(novoContato, etapa, values);
    }

    setLoading(false);
  };

  const handleOpenCardDlg = (card, etapa) => {
    setSelectedCard(card);
    setSelectedEtapa(etapa);
  };

  const handleCloseCardDlg = () => {
    setSelectedCard(null);
    setSelectedEtapa(null);
  };

  return (
    <>
      {loading && (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      )}
      {etapas && !loading && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Container>
            <SearchContainer>
              <Box display="flex" flexdirection="column">
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleNovoClick}
                >
                  Novo
                </Button>
                <CardNovoDlg
                  show={showNovo}
                  token={chave}
                  etapas={Object.values(etapas.etapas).filter(
                    (etapa) => etapa.permite_entrada
                  )}
                  handleSave={handleNovoSave}
                  onClose={handleNovoClose}
                />
              </Box>
              <TextField
                type="text"
                placeholder="Pesquisar"
                variant="outlined"
                size="small"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
              {(searchValue !== "" || cardToFilter) && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setSearchValue("");
                    setCardToFilter(null);
                  }}
                >
                  Limpar filtro
                </Button>
              )}
              <Box display="flex" alignItems="center" gap={1}>
                <Typography>{`${
                  Object.keys(etapas.cards).length
                } cards`}</Typography>

                <BarraProporcional statusCounts={contagem} maxSize={350} />
              </Box>
            </SearchContainer>
            <EtapasContainer>
              {etapas.etapasOrder.map((etapaId, index) => {
                const color = colors[index];
                const etapa = etapas.etapas[etapaId];
                let cards = etapa.cardIds.map((cardId) => etapas.cards[cardId]);
                if (cardToFilter) {
                  cards = cards.filter(
                    (card) => card.id === `card-${cardToFilter.id}`
                  );
                } else {
                  if (searchValue !== "") {
                    cards = cards.filter(
                      (card) =>
                        card.contato.nome
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()) ||
                        card.contato.whatsapp
                          .toString()
                          .includes(searchValue) ||
                        card.contato.cidade
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())
                    );
                  }
                }

                return (
                  <Etapa
                    key={etapa.id}
                    etapa={etapa}
                    cards={cards}
                    token={chave}
                    setCard={setCard}
                    removeCard={removeCard}
                    setOpenEditDlg={handleOpenCardDlg}
                    limite={
                      etapaId === etapas.etapasOrder[0] ||
                      etapaId === etapas.etapasOrder[1]
                        ? parametros.limite_cards_primeira_etapa
                        : 0
                    }
                    color={color}
                  />
                );
              })}
            </EtapasContainer>
            <Toaster toastOptions={{ duration: 5000 }} />
            {selectedCard && (
              <CardEditDlg
                token={chave}
                card={selectedCard}
                etapa={selectedEtapa}
                open={selectedCard !== null}
                onClose={handleCloseCardDlg}
              />
            )}
          </Container>
        </DragDropContext>
      )}
    </>
  );
});

export default FunilDrag;
