import { Box, IconButton, Tooltip } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faProductHunt } from "@fortawesome/free-brands-svg-icons";
import ContactsIcon from "@mui/icons-material/Contacts";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import ListIcon from "@mui/icons-material/List";

export default function SideOption({
  option,
  title,
  menuLateral,
  handleMenuLateralClick,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 2,
      }}
    >
      <Tooltip title={title} placement="right" arrow>
        <IconButton
          sx={{
            width: 40,
            height: 40,
            border: menuLateral.funil ? "solid 1px" : "none",
          }}
          onClick={() => {
            handleMenuLateralClick(option);
          }}
        >
          {option === "funil" ? (
            <FilterAltIcon
              sx={{
                width: 20,
                height: 20,
              }}
            />
          ) : option === "lista" ? (
            <ListIcon
              sx={{
                width: 20,
                height: 20,
              }}
            />
          ) : option === "clientes" ? (
            <ContactsIcon
              sx={{
                width: 20,
                height: 20,
              }}
            />
          ) : option === "cardsInativos" ? (
            <CreditCardOffIcon
              sx={{
                width: 20,
                height: 20,
              }}
            />
          ) : (
            <FontAwesomeIcon size="sm" icon={faProductHunt} />
          )}
        </IconButton>
      </Tooltip>
    </Box>
  );
}
