import React, { forwardRef } from "react";
import { IconButton } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const WhatsAppLink = forwardRef(function WhatsAppLink(
  { phoneNumber, disabled = false },
  ref
) {
  const whatsappUrl = `https://wa.me/55${phoneNumber}`;

  return (
    <>
      {disabled ? (
        <IconButton disabled>
          <WhatsAppIcon sx={{ color: "grey" }} />
        </IconButton>
      ) : (
        <IconButton
          // color="primary"
          component="a"
          href={whatsappUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <WhatsAppIcon sx={{ color: "green" }} />
        </IconButton>
      )}
    </>
  );
});

export default WhatsAppLink;
