import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CurrencyTextField from "../CurrencyTextField";
import ConfirmationDialog from "../Dialogs/ConfirmationDialog";

const CurrencyEditableLabel = ({ initialText, onChange, onConfirm }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(initialText);
  const [open, setOpen] = useState(false);
  const [valorInicial, setValorInicial] = useState(initialText);

  const handleTextClick = () => {
    setIsEditing(true);
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
    console.log("text: ", text);
    console.log("initialText: ", initialText);
    console.log("valorInicial: ", valorInicial);
    if (text !== valorInicial) {
      setOpen(true);
    }
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
    setOpen(false);
    setIsEditing(false);
    setValorInicial(text);
  };

  const handleClose = () => {
    setOpen(false);
    setText(valorInicial);
  };

  const formatCurrency = (value) => {
    const formattedValue = parseFloat(value).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return formattedValue;
  };

  return (
    <Box>
      {isEditing ? (
        <CurrencyTextField
          value={text}
          onChange={handleTextChange}
          onBlur={handleBlur}
          autoFocus
          variant="standard"
        />
      ) : (
        <Typography
          onClick={handleTextClick}
          sx={{ cursor: "pointer", fontWeight: "bold" }}
        >
          {text ? formatCurrency(text) : ""}
        </Typography>
      )}
      <ConfirmationDialog
        open={open}
        onClose={handleClose}
        onConfirm={handleConfirm}
        title="Valor do Contrato"
        message="Confirma alteração no valor do contrato?"
      />
    </Box>
  );
};

export default CurrencyEditableLabel;
