import { Box, CircularProgress, Typography } from "@mui/material";
import WhatsAppLink from "../WhatsAppLink";
import { useState } from "react";
import CelularEdit from "../Funil/CelularEdit";
import { updContato } from "../../services/SrvContato";
import toast, { Toaster } from "react-hot-toast";

export default function ContatoWhats({
  token,
  contatoId,
  whatsapp,
  card = null,
}) {
  const [editing, setEditing] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [value, setValue] = useState(whatsapp ? whatsapp : "");
  const [oldValue, setOldValue] = useState(whatsapp ? whatsapp : "");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const update = async () => {
    setUpdating(true);
    let whatsapp = value.replace(/[^0-9]/g, "");
    whatsapp = parseInt(whatsapp);
    await updContato(
      {
        id: contatoId,
        whatsapp: whatsapp,
      },
      token
    ).then(
      (resp) => {
        if (card) card.contato.whatsapp = value;
        setEditing(false);
        setUpdating(false);
      },
      (erro) => {
        toast.error(`Erro ao atualizar: ${erro.response.data.detail}`);
        setUpdating(false);
      }
    );
  };

  return (
    <Box display="flex" justifyContent="space-between" gap={1}>
      {editing ? (
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <CelularEdit
            label="WhatsApp"
            id="whatsapp"
            name="whatsapp"
            variant="standard"
            fullWidth
            required
            value={value}
            onChange={handleChange}
            onBlur={() => update()}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                update();
                return;
              }
              if (event.key === "Escape") {
                setValue(oldValue);
                setEditing(false);
              }
            }}
          />
          {updating && <CircularProgress color="inherit" size={20} />}
        </Box>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <Typography
            onClick={() => {
              setOldValue(value);
              setEditing(true);
            }}
          >
            {value}
          </Typography>
          <WhatsAppLink phoneNumber={value} />
        </Box>
      )}
      <Toaster />
    </Box>
  );
}
