import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { partialUpdParametroAssinante } from "../services/SrvParametro";
import toast, { Toaster } from "react-hot-toast";

export default function ParametroAssinanteCRM({
  token,
  assinante,
  parametros,
}) {
  const [editingId, setEditingId] = useState(null);
  const [tempValue, setTempValue] = useState(null);

  const handleEditClick = (id, value) => {
    setEditingId(id);
    setTempValue(value);
  };

  const handleInputChange = (event) => {
    setTempValue(event.target.value);
  };

  const handleCancelClick = () => {
    setTempValue("");
    setEditingId(null);
  };

  const handleUpdateClick = async (param) => {
    parametros[param] = tempValue;

    var dados = { assinante: assinante };
    dados[param] = tempValue;

    await partialUpdParametroAssinante(token, dados).then(
      (resp) => {
        console.log("parametro atualizado: ", resp);
        toast.success("Parametro atualizado com sucesso!");
      },
      (erro) => {
        toast.error(
          `Ocorreu um erro ao atualizar o parametro: ${erro.response.data.detail}`
        );
      }
    );

    setEditingId(null);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Valor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key="limite_cards_primeira_etapa"
              rowkey="limite_cards_primeira_etapa"
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>Limite de cards visíveis na primeira etapa</TableCell>
              <TableCell>
                {editingId === "limite_cards_primeira_etapa" ? (
                  <TextField
                    value={tempValue}
                    onChange={handleInputChange}
                    size="small"
                    type="number"
                  />
                ) : (
                  <span
                    style={{ cursor: "pointer", color: "blue" }}
                    onClick={() =>
                      handleEditClick(
                        "limite_cards_primeira_etapa",
                        parametros.limite_cards_primeira_etapa
                      )
                    }
                  >
                    {parametros.limite_cards_primeira_etapa}
                  </span>
                )}
              </TableCell>
              <TableCell align="center">
                {editingId === "limite_cards_primeira_etapa" && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() =>
                        handleUpdateClick("limite_cards_primeira_etapa")
                      }
                    >
                      Atualizar
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={() => handleCancelClick()}
                    >
                      Cancelar
                    </Button>
                  </Box>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Toaster toastOptions={{ duration: 3000 }} />
    </>
  );
}
