import axios from "axios";

const baseAPI = process.env.REACT_APP_BASE_API_URL
const funilAssinanteAPI = axios.create({ baseURL: `${baseAPI}api/funil/funilassinante` })
const etapaFunilAssinanteAPI = axios.create({ baseURL: `${baseAPI}api/funil/etapafunilassinante` })

async function listFunilAssinante(token) {
    const authToken = `Token ${token}`
    const resp = await funilAssinanteAPI.get('/', { headers: { Authorization: authToken } })
    return resp.data
}

async function getFunilAssinante(token, pk) {
    const authToken = `Token ${token}`
    const resp = await funilAssinanteAPI.get(`/${pk}/`, { headers: { Authorization: authToken } })
    return resp.data
}

async function getFunilAssinanteByAssinante(token, assinante_id) {
    const authToken = `Token ${token}`
    const resp = await funilAssinanteAPI.get(`/assinante/${assinante_id}/`, { headers: { Authorization: authToken } })
    return resp.data
}

async function createFunilAssinante(token, assinante_id, templatefunil_id) {
    const authToken = `Token ${token}`
    const resp = await funilAssinanteAPI.post(`/create/${assinante_id}/${templatefunil_id}/`, {}, { headers: { Authorization: authToken, "Content-Type": "application/json" } })
    return resp.data
}

async function addFunilAssinante(token, dados) {
    const authToken = `Token ${token}`
    const resp = await funilAssinanteAPI.post('/', dados, { headers: { Authorization: authToken, "Content-Type": "application/json" } })
    return resp.data
}

async function updFunilAssinante(token, dados) {
    const authToken = `Token ${token}`
    const resp = await funilAssinanteAPI.post(`/${dados.id}/`, dados, { headers: { Authorization: authToken, "Content-Type": "application/json" } })
    return resp.data
}

async function delFunilAssinante(token, pk) {
    const authToken = `Token ${token}`
    const resp = await funilAssinanteAPI.delete(`/${pk}`, { headers: { Authorization: authToken } })
    return resp.data
}

async function listEtapaFunilAssinante(token) {
    const authToken = `Token ${token}`
    const resp = await etapaFunilAssinanteAPI.get('/', { headers: { Authorization: authToken } })
    return resp.data
}

async function getEtapaFunilAssinante(token, pk) {
    const authToken = `Token ${token}`
    const resp = await etapaFunilAssinanteAPI.get(`/${pk}/`, { headers: { Authorization: authToken } })
    return resp.data
}

async function addEtapaFunilAssinante(token, dados) {
    const authToken = `Token ${token}`
    const resp = await etapaFunilAssinanteAPI.post('/', dados, { headers: { Authorization: authToken, "Content-Type": "application/json" } })
    return resp.data
}

async function updEtapaFunilAssinante(token, dados) {
    const authToken = `Token ${token}`
    const resp = await etapaFunilAssinanteAPI.post(`/${dados.id}/`, dados, { headers: { Authorization: authToken, "Content-Type": "application/json" } })
    return resp.data
}

async function delEtapaFunilAssinante(token, pk) {
    const authToken = `Token ${token}`
    const resp = await etapaFunilAssinanteAPI.delete(`/${pk}`, { headers: { Authorization: authToken } })
    return resp.data
}

export {
    listFunilAssinante, getFunilAssinante, createFunilAssinante, addFunilAssinante, updFunilAssinante, delFunilAssinante,
    listEtapaFunilAssinante, getEtapaFunilAssinante, updEtapaFunilAssinante, delEtapaFunilAssinante, getFunilAssinanteByAssinante
}
