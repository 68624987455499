import React from "react";
import CRMTooltip from "../CRMTooltip/CRMTooltip";

const NumberedStar = ({ number, size = "medium" }) => {
  // Definição dos tamanhos baseados no Material UI FAB
  const sizeMap = {
    small: 30,
    medium: 40, // Padrão
    large: 65,
  };

  // Cores baseadas no número
  const backgroundColors = {
    1: "#FFF9C4", // Amarelo claro
    2: "#FFEB3B", // Amarelo médio
    3: "#FBC02D", // Amarelo forte
  };

  // Garante que o número esteja dentro do esperado (1, 2 ou 3)
  const validNumber = [1, 2, 3].includes(number) ? number : 1;

  // Define o tamanho da estrela
  const starSize = sizeMap[size] || sizeMap.medium;

  return (
    <CRMTooltip text={number}>
      <div
        style={{
          position: "relative",
          width: starSize,
          height: starSize,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* SVG da estrela */}
        <svg
          viewBox="0 0 24 24"
          width={starSize}
          height={starSize}
          fill={backgroundColors[validNumber]}
        >
          <polygon
            points="12,2 15,9 22,9 16.5,14 18,22 12,18 6,22 7.5,14 2,9 9,9"
            stroke="#000"
            strokeWidth="1"
          />
        </svg>

        {/* Número centralizado */}
        <span
          style={{
            position: "absolute",
            fontSize: starSize * 0.4,
            fontWeight: "bold",
            color: "#fff",
            textShadow: "1px 1px 2px rgba(0, 0, 0, 0.7)",
          }}
        >
          {validNumber}
        </span>
      </div>
    </CRMTooltip>
  );
};

export default NumberedStar;
