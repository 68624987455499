import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import HomeBar from "../components/HomeBar/HomeBar";
import { getLoggedUser } from "../services/SrvLogin";
import ReactECharts from "echarts-for-react";
import { Box, Container, Typography } from "@mui/material";

function Home() {
  var autenticado = false;
  var chave = null;
  var perfil = null;

  const location = useLocation();
  const { state } = location;
  if (state) {
    autenticado = state.isAuthenticated;
    chave = state.token;
    perfil = state.profile;
  }
  // console.log(autenticado, chave, perfil);

  const [countStatus, setCountStatus] = React.useState({});
  const [countGeral, setCountGeral] = React.useState({});
  const [countEtapaStatus, setCountEtapaStatus] = React.useState({});
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  const chartContainerRef = React.useRef(null);

  useEffect(() => {
    const fetchCards = async () => {
      if (!chave) {
        return;
      }
      try {
        const response = await getLoggedUser(chave);
        const totalCards = response.cards;

        // console.log("totalCards: ", totalCards);

        const contador = {
          total: totalCards.length,
          ativos: totalCards.filter((card) => card.status === "A").length,
          inativos: totalCards.filter((card) => card.status === "I").length,
        };

        setCountGeral(contador);

        const activeCards = totalCards.filter((card) => card.status === "A");

        const contaStatusExp = activeCards.reduce((acc, card) => {
          acc[card.status_expiracao] = (acc[card.status_expiracao] || 0) + 1;
          return acc;
        }, {});

        setCountStatus(contaStatusExp);

        const countEtapas = activeCards.reduce((acc, card) => {
          const etapaNome = card.etapa?.nome || "Sem etapa";
          if (!acc[etapaNome]) {
            acc[etapaNome] = {
              A: 0,
              P: 0,
              E: 0,
              Ordem: card.etapa?.ordem || 0,
            };
          }
          acc[etapaNome][card.status_expiracao] += 1;
          return acc;
        }, {});

        // console.log("countEtapas: ", countEtapas);
        const etapasOrdenadas = Object.entries(countEtapas)
          .sort(([, a], [, b]) => a.Ordem - b.Ordem)
          .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {});

        // console.log("etapasOrdenadas: ", etapasOrdenadas);
        setCountEtapaStatus(etapasOrdenadas);

        setIsDataLoaded(true);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCards();
  }, [chave]);

  useEffect(() => {
    const resizeCharts = () => {
      setTimeout(() => {
        if (chartContainerRef.current?.clientWidth > 0) {
          window.dispatchEvent(new Event("resize"));
        }
      }, 100);
    };
    resizeCharts();
  }, [isDataLoaded]);

  const colors = {
    A: "red", // atrasado
    P: "yellow", // proximo
    E: "green", // em dia
  };

  const labels = {
    A: "Atrasado",
    P: "Próximo de expirar",
    E: "Em dia",
  };

  const etapas = Object.keys(countEtapaStatus);
  const statusKeys = ["A", "P", "E"];

  const etapaOptions = {
    title: {
      text: "Status de Expiração por Etapa (ativos)",
      left: "center",
    },
    tooltip: { trigger: "axis", axisPointer: { type: "shadow" } },
    legend: {
      orient: "horizontal",
      bottom: 0,
      left: "center",
      // top: "center",
      data: statusKeys.map((key) => labels[key]),
    },
    xAxis: {
      type: "category",
      data: etapas,
    },
    yAxis: {
      type: "value",
    },
    series: statusKeys.map((key) => ({
      name: labels[key],
      type: "bar",
      stack: "total",
      data: etapas.map((etapa) => countEtapaStatus[etapa][key] || 0),
      itemStyle: {
        color: colors[key],
      },
    })),
  };

  const barOptions = {
    title: {
      text: "Status de Expiração (ativos)",
      left: "center",
    },
    tooltip: {},
    legend: {
      data: Object.values(labels),
    },
    xAxis: {
      data: Object.keys(countStatus).map((key) => labels[key] || key),
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "Status",
        type: "bar",
        data: Object.keys(countStatus).map((key) => ({
          value: countStatus[key],
          itemStyle: {
            color: colors[key],
          },
        })),
      },
    ],
  };

  const pieOptions = {
    title: {
      text: "% Status de Expiração (ativos)",
      left: "center",
    },
    tooltip: {
      trigger: "item",
      formatter: "{b}: {c} ({d}%)",
    },
    legend: { orient: "horizontal", bottom: 0, data: Object.values(labels) },
    series: [
      {
        name: "Percentual",
        type: "pie",
        radius: "50%",
        data: Object.keys(countStatus).map((key) => ({
          name: labels[key] || key,
          value: ((countStatus[key] / countGeral.total) * 100).toFixed(2),
          itemStyle: {
            color: colors[key] || "#000000",
          },
        })),
      },
    ],
  };

  return (
    <>
      {autenticado ? (
        <>
          <HomeBar
            nome={perfil.nome}
            token={chave}
            profile={perfil}
            isAuthenticated={autenticado}
          />
          <Container
            ref={chartContainerRef}
            sx={{
              marginTop: 10,
              paddingTop: "69px",
              border: "1px solid #000",
              backgroundColor: "#f0f0f0",
              padding: 5,
            }}
          >
            {isDataLoaded && Object.keys(countStatus).length > 0 ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      alignItems: "center",
                      width: "100%",
                      // height: 100,
                      backgroundColor: "blue",
                      padding: 2,
                    }}
                  >
                    <Typography variant="h4" color="white" fontWeight="bold">
                      Total
                    </Typography>
                    <Typography variant="h3" color="white" fontWeight="bold">
                      {countGeral.total}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      alignItems: "center",
                      width: "100%",
                      // height: 100,
                      backgroundColor: "blue",
                      padding: 2,
                    }}
                  >
                    <Typography variant="h4" color="white" fontWeight="bold">
                      Ativos
                    </Typography>
                    <Typography variant="h3" color="white" fontWeight="bold">
                      {countGeral.ativos}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      alignItems: "center",
                      width: "100%",
                      // height: 100,
                      backgroundColor: "blue",
                      padding: 2,
                    }}
                  >
                    <Typography variant="h4" color="white" fontWeight="bold">
                      Inativos
                    </Typography>
                    <Typography variant="h3" color="white" fontWeight="bold">
                      {countGeral.inativos}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    width: "100%",
                    maxWidth: 1200,
                    minHeight: 250,
                    // border: "1px solid #000",
                    backgroundColor: "#fff",
                    padding: 5,
                  }}
                >
                  <ReactECharts
                    option={barOptions}
                    style={{ height: 250, width: "100%" }}
                  />
                  <ReactECharts
                    option={pieOptions}
                    style={{ height: 250, width: "100%" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    width: "100%",
                    maxWidth: 1200,
                    minHeight: 250,
                    // border: "1px solid #000",
                    backgroundColor: "#fff",
                    padding: 5,
                  }}
                >
                  <ReactECharts
                    option={etapaOptions}
                    style={{ height: 250, width: "100%" }}
                  />
                </Box>
              </>
            ) : (
              <p>Carregando gráficos...</p>
            )}
          </Container>
        </>
      ) : (
        <Navigate to={"/login"} />
      )}
    </>
  );
}

export default Home;
